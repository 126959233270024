import React from 'react';
import logo from 'img/logo_color.svg';
import { NavLink, Link } from 'react-router-dom';
import menu from 'img/ic_menu.svg';
import close from 'img/ic_close.svg';


class Header extends React.Component{

    closeMenuToggle = () => {

        const chkbox = document.querySelector('#gnb-mobile');
        chkbox.checked = false;
    }

    render(){
        return(
            <header>
                <div className="con-default">
                    <div className="Header__logo">
                        <Link to ="/">
                            <img src={logo} alt="logo"></img>
                        </Link >
                    </div>
    
                    <ul className="Header__gnb gnb-web">
                        <li><NavLink exact to ="/" activeClassName="gnb-active">home</NavLink></li>
                        <li><NavLink to ="/portfolio" activeClassName="gnb-active">portfolio</NavLink></li>
                        <li><NavLink to ="/designtalk" activeClassName="gnb-active">design talk</NavLink></li>
                        <li><NavLink to ="/contact" activeClassName="gnb-active">contact</NavLink></li>
                    </ul>
    
                    {/* mobile nav */}
                    <div className = "gnb-mobile-wrap">
                        <input type = "checkbox" id = "gnb-mobile"/>
    
                        <div className="cover-menu"></div>
    
                        <label htmlFor = "gnb-mobile" className = "gnb-mobile-icon ic-menu">
                            <img src = {menu} alt = "gnb-mobile"/>
                        </label>
    
                        <label htmlFor="gnb-mobile" className = "gnb-mobile-icon ic-close">
                            <img src = {close} alt = "gnb-mobile-close" />
                        </label>
    
    
                        <ul className="gnb-mobile">
                            <li onClick={this.closeMenuToggle}><NavLink exact to ="/" activeClassName="gnb-active">home</NavLink></li>
                            <li onClick={this.closeMenuToggle}><NavLink to ="/portfolio" activeClassName="gnb-active">portfolio</NavLink></li>
                            <li onClick={this.closeMenuToggle}><NavLink to ="/designtalk" activeClassName="gnb-active">design talk</NavLink></li>
                            <li onClick={this.closeMenuToggle}><NavLink to ="/contact" activeClassName="gnb-active">contact</NavLink></li>
                        </ul>
                    </div>
                </div>
    
    
            </header>
        )
    }
    
}

export default Header;