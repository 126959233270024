import React ,{useState, useEffect, useContext} from 'react';
import {HeaderAdmin, AdminTitle, InputText} from 'components/admin'
import { AppContext } from '../shared/App';



const AdminContact = () => {
  const {db, auth, storage} = useContext(AppContext);
  const [disabled, setDisabled] = useState(true);
  const [form, setForm] = useState();
  const [newImage, setNewImage] = useState();
  const [preImage,setPreImage] = useState('');

  useEffect(()=>{

    const _get = () => {
      const form = db.ref(`contact`);
      let returnForm = null;
      form.on('value', snapshot => {
        returnForm = snapshot.val();
        setForm(returnForm);
      });
    }
    
    _get();
  }, [db])


  const onClickLogout = () => {
    auth.signOut();
  }

  const handleChange = (e) => {
    switch(e.target.type){
      case 'file': {
        if (!e.target.files[0].type.match(/image\//)) return;

        // 이전 이미지 저장
        const preImage = form.map.imgName;
        setPreImage(preImage);

        // 새로운 이미지 저장
        const newFile = e.target.files[0];
        let newURL = '';
        var reader = new FileReader();
        reader.readAsDataURL(newFile);
        reader.onload = async () => {
          const newForm = {...form};
          newURL = await reader.result;
          newForm.map.imgName = newFile.name;
          newForm.map.imgURL = newURL;
          setForm(newForm);
        };

        setNewImage(newFile);

        break;
      }

      default : {
        const newState = {...form};
        newState[e.target.name] = e.target.value;
        setForm(newState);
        break;
      }
    }
    
  }

  const changeContact = (form) => {
    
    let nowDisabled =  disabled;
    nowDisabled =! nowDisabled;
    if(nowDisabled){
      handleSubmit(form);
    }
    setDisabled(nowDisabled);
  }

  const handleSubmit = (form) => {
    form.map.imgFile ? 
    _postStorage(form) :
    _postForm(form);
  }

  const _postStorage = () => {
    const file = newImage;

    // 다른 파일 삭제하기
    storage.ref(`/contact`).child(preImage).delete();

    const uploadTask = storage.ref(`/contact/${file.name}`).put(file);
    uploadTask.on(`state_changed`,
      (snapshot) =>{
          // progress function
      }, 
      (error) =>{
          // error function
          console.log(error);
      }, 
      ()=>{
        // complete function
        storage.ref(`contact/`).child(file.name).getDownloadURL()
        .then( url => {
          _postForm({
            ...form,
            map: {
              ...form.map,
              imgURL: url,
            }
          })
        })
      });

  }

  const _postForm = (form) => {
    db.ref(`contact`).set(form, error => {
      if(error){
        console.log(error);
      }else{
        alert("수정되었습니다.");
      }
    });
  }


  const renderInput = () =>{
    return <div className = "contact-form">
      <InputText disabled = {disabled} con_title = { form.headQuarter} handleChange = { handleChange} title = "Head Quarter" name = "headQuarter" type = "text" value = {form.headQuarter}/>
      <InputText disabled = {disabled} con_title = { form.ecritsLab} handleChange = { handleChange} title = "Ecrits-Lab" name = "ecritsLab" type = "text" value = {form.ecritsLab}/>
      <InputText disabled = {disabled} con_title = { form.tell} handleChange = { handleChange} title = "tell" name = "tell" type = "text" value = {form.tell}/>
      <InputText disabled = {disabled} con_title = { form.eMail} handleChange = { handleChange} title = "e-mail" name = "eMail" type = "text" value = {form.eMail}/>
      
      <InputText 
        disabled = {disabled}
        handleChange = { handleChange} 
        title = "지도 이미지" 
        name = "imgName"  
        type = "file"
        index = "imgName"
        filename = { form.map.imgName}
        imgRootURL = { form.map.imgURL}
      />
      <div className = "preview">
        <img src = { form.map.imgURL} alt = { form.map.imgName}/>
      </div>
    </div>
  }



    return(
      <div className="AdminContact size-header">
        <HeaderAdmin/>
        <div className = "con-admin">
          <AdminTitle title = "contact" addBtn = "false" onClickLogout = {onClickLogout}/>
          
          <div className = "wrap-btn">
            <p className = "btn btn-addContents" onClick = {() => {changeContact(form)}} >{ disabled ? "수정하기" : "완료"}</p>
          </div>
          
          { form ? renderInput() : "Loading"}
          

        </div>
      </div>
    )

}

export default AdminContact;