import React from 'react';

function Nothing(){
  return(
    <div className="Nothing">
      <p>don't have a project yet.</p>
    </div>
  )
}

export default Nothing;