import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {Loading, Nothing} from 'components';
import { AppContext } from '../shared/App';

const Posts = (props) => {
    const [portfolios, setPortfolios] = useState();
    const {db} = useContext(AppContext);

    useEffect(()=> {
        const _getApi = async () =>{
            return await db.ref(`${props.category}`).once('value')
            .then(function(snapshot) {
                let result = snapshot.val();

                Object.keys(result).forEach(el => {
                    result[el].id = el;
                })

                return result;
            })
            .catch(err => {
                console.log(err);
            })
        }
    
        const _getPortfolio = async () =>{
            const portfolio = await  _getApi();
            setPortfolios(portfolio);
        }
    
        _getPortfolio();
    }, [db, props])

    
    const _renderPortfolio = () => {
        const filter =  props.filter;
        const portfolios_temp = Object.keys(portfolios).map(el => {
            return portfolios[el];
        });

        const portfolios_sort = portfolios_temp.sort((a,b)=>{
            return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
        });
        let portfolio = null;

        if(filter){
            portfolio = portfolios_sort.map( portfolio => {
                return filter === "all" ? 
                    <Link to= {`/${ props.category}/${portfolio.id}`} key ={portfolio.id}>
                        <Post 
                        title = {portfolio.title}
                        client = {portfolio.client}
                        date = {portfolio.date}
                        descript = {portfolio.description}
                        thumbnail = {portfolio.thumbnail}
                        handleElement = { props.handleElement}
                    />
                    </Link> :
                    portfolio.type === filter ?
                    <Link to= {`/${ props.category}/${portfolio.id}`} key ={portfolio.id}>
                        <Post 
                        title = {portfolio.title}
                        client = {portfolio.client}
                        date = {portfolio.date}
                        descript = {portfolio.description}
                        thumbnail = {portfolio.thumbnail}
                        handleElement = {props.handleElement}
                        />
                    </Link>
                    : ""
            })
        }else{
            portfolio = portfolios_sort.map( portfolio => {
                return <Link to= {`/${ props.category}/${portfolio.id}`} key ={portfolio.id}>
                            <Post 
                                title = {portfolio.title}
                                client = {portfolio.client}
                                date = {portfolio.date}
                                descript = {portfolio.description}
                                thumbnail = {portfolio.thumbnail}
                                handleElement = {props.handleElement}
                                />
                        </Link>
            })
        }
        return portfolio;
    }

    const _handleLoading = () => {
        console.log(portfolios);
        if(portfolios === undefined){
            return <Nothing />
        }else{
            return <Loading />
        }
    }

    return(
        <div>
            <div className="posts">
                <div className="con-default">
                    <div className="post-wrap">
                        {portfolios ?  _renderPortfolio() :  _handleLoading()}
                    </div>
                </div>
            </div>
        </div>
    )

   
}

function Post(props){
    return(
        <div className="post">
            <div className="post-thumb" style={{backgroundImage: `url(${props.thumbnail})` }}></div>
            <div className="post-contents">
                <div className="contents-wrap">
                    <div className="contents-text">

                        <div className="contents-text-thumb">
                            <h5 className="post-title">{props.title}</h5>
                            <p className="post-client">{props.client}</p>
                            <p className="post-date">{props.date}</p>   
                        </div>

                        <div className="contents-text-detail">
                            <div className="text-thumb-wrap">
                            </div>
                            <p className="post-descrsiption"> {props.descript} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Posts;