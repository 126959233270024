import React from 'react';


class PortfolioLists extends React.Component{

    renderPortfolio = (props) =>{
        const portfolios = this.props.portfolios;
        const portfolios_values = Object.values(portfolios).sort((a,b) => {
            return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
        })

        return portfolios_values.map(portfolio => {
            const key = Object.keys(portfolios).find(key => portfolios[key].title === portfolio.title)
            
            return <PortfolioList 
                    thumbnail = {portfolio.thumbnail}
                    title = {portfolio.title}
                    client = {portfolio.client}
                    date = {portfolio.date}
                    key = {portfolio.title}
                    id = {key}
                    handlePortfolioEdit = {(id) => {props.handlePortfolioEdit(key)}}
                    handlePortfolioDelete = {(id) => {props.handlePortfolioDelete(key)}}
                    />
        })
    }

    render(){
        return(
            <div style = {this.props.style}  className = "PortfolioLists">
                {this.renderPortfolio(this.props)}
            </div>
        )
    }
}

function PortfolioList(props){
    return(
        <div className="PortfolioList">
            <div className="wrap-img">
                <img src = {props.thumbnail} alt={props.title} />
            </div>

            <div className = "wrap-text">
                <h5>{props.title}</h5>
                <p>{props.client}</p>
                <p>{props.date}</p>
            </div>

            <div className = "wrap-btn">
                <p className="btn" onClick = {() => props.handlePortfolioEdit(props.id)}>수정</p>
                <p className="btn" onClick = {() => props.handlePortfolioDelete(props.id)}>삭제</p>
            </div>
        </div>

    )
    
}

export default PortfolioLists;