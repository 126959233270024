import React from 'react';

function ProjectSession(props){
    return(
        <div className="projectSession contents">
            <div className="con-default">
                <div className="width-description">
                    <h3>{props.title}</h3>
                    <p>{props.descript}</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectSession;