import React from 'react';
import {Posts, Fillter} from 'components';
import 'scss/designtalk.scss';

function Education(){
    return(
        <div className="size-header disignTalk">
            <Fillter  />
            <div className="con-default">
                <h1>Design talk</h1>
            </div>

            <Posts category = "designtalk"/>
        </div>
    )
}

export default Education;