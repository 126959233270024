import React from 'react';
import logo from 'img/logo_color.svg'

import { NavLink, Link } from 'react-router-dom';

function HeaderAdmin(props){
    return(
        <header id="header-admin">
            <div className="con-default">
                <div className="Header__logo">
                    <Link to ="/adminhome">
                        <img src={logo} alt="logo"></img>
                    </Link >
                    <h3>admin</h3>
                </div>
                {props.type 
                ? ""
                : <ul className="Header__gnb">
                <li><NavLink exact to ="/adminhome" activeClassName="gnb-active">home</NavLink></li>
                <li><NavLink to ="/adminportfolio" activeClassName="gnb-active">portfolio</NavLink></li>
                <li><NavLink to ="/admindesigntalk" activeClassName="gnb-active">design talk</NavLink></li>
                <li><NavLink to ="/admincontact" activeClassName="gnb-active">contact</NavLink></li>
                
            </ul>
                }

            </div>


        </header>
    )
}

export default HeaderAdmin;