import React, {useState, useEffect, useContext} from 'react';
import ContactTable from './ContactTable';
import { AppContext } from '../shared/App';

const Contact = (props) => {
    const {db} = useContext(AppContext);
    const [form, setForm] = useState();
    useEffect(() => {
        _getContact()
    })

    const _get = async () =>{
        return await db.ref(`contact`).once('value')
        .then(function(snapshot) {
            return snapshot.val();
        })
        .catch(err => {
            console.log(err);
        })
    }

    const _getContact = async() => {
        const formContact = await _get();
        setForm(formContact)
    }



    return(
        <section className = {props.class}>
            <div className="sec-story sec-full">
                <div className="bg-story bg-story-left"></div>

                <div className="con-default">
                    <div className ="story-text">
                        <div className ="story-title">
                            <h3>{props.title}</h3>
                        </div>

                        {form ? <ContactTable form = {form} /> : ""}
                        
                        
                    </div>
                </div>
            </div>
        </section>
    )

    
}

export default Contact;