import React, {useState, useEffect, useContext} from 'react';
import {PageTitle, ContactTable, ContactMap, Loading} from 'components'
import 'scss/contact.scss';
import { AppContext } from '../shared/App';

const Contact = () => {
    const [form, setForm] = useState();
    const {db} = useContext(AppContext);

    useEffect(() => {
        const _get = async () =>{
            return await db.ref(`contact`).once('value')
            .then(function(snapshot) {
                return snapshot.val();
            })
            .catch(err => {
                console.log(err);
            })
        }

        const _getContact = async() => {
            const formContact = await _get();
            setForm(formContact);
        }
        
        _getContact();
    }, [db])

   



    const renderContact = () => {
        return <div>
                    <div className="con-default">
                        <ContactTable form = { form}/>
                    </div>
                    <ContactMap map = { form.map}/>
                </div>
    }

    return(
        <div className="contact size-header">

            <PageTitle title="contact"/>
            { form ? renderContact() : <Loading />}
            
        </div>
    )
    
}

export default Contact;