import React, {useState, useEffect, useContext} from 'react';

import {Loading,Footer, PageTitle,ProjectDescript, ProjectDetails, ProjectImage, ProjectSession} from 'components';
import 'scss/detail.scss';
import { AppContext } from '../shared/App';

const Detail = (props) => {
    const [portfolio, setPortfolio] = useState();
    const {db} = useContext(AppContext);
    
    useEffect(() => {
        const _getApi = async () =>{
            return await db.ref(`portfolio`).once('value')
            .then(function(snapshot) {
                let result = snapshot.val();

                Object.keys(result).forEach(el => {
                    result[el].id = el;
                })

                return result;
            })
            .catch(err => {
                console.log(err);
            })
        }

        const _getContents = async() =>{
            const data = await _getApi();
            const id =  props.match.params.id;

            Object.keys(data).map( el => {
                if(data[el].id === id){
                    let portfolio = data[el];

                    setPortfolio(portfolio);
                }
                return "";
            })
        }

        _getContents()
    }, [db, props])

    

    const _renderContents = () =>{        
        const contents =  portfolio.contents.map( el =>{
            if(el.type==="image"){
                return <ProjectImage img={el.contents} name={el.contents} key={el.index}/>
            }else{
                return <ProjectSession title={el.title} descript={el.description}  key={el.index}/>
            }
        })

        return (
            <div>
                <PageTitle title = { portfolio.title}/>
                <ProjectDetails client = { portfolio.client} date = { portfolio.date} />
                <ProjectDescript description = { portfolio.description}/>
                {contents}
                <Footer />
            </div>
        )
    }

    // 찾아야 하는 대상
    return(
        <div className="size-header detail">
            { portfolio ? _renderContents() : <Loading />}
        </div>

    )
}

export default Detail;