import React, {useState, useEffect, useContext} from 'react';
import { Cover,Story,Contact,Footer, Loading } from 'components';
import 'scss/index.scss';
import { AppContext } from '../shared/App';

const Home = () => {
    // field
    const [lists, setLists] = useState({})
    const {db} = useContext(AppContext);

    useEffect(()=>{
            
        // get method
        const _get = async () => {
            return await db.ref(`homeThumbnail`).once('value')
            .then(function(snapshot) {
                return snapshot.val();
            })
            .catch(err => {
                console.log(err);
            })
        }

        const _getLists = async () =>{
            const lists_temp = await _get();
            setLists(lists_temp);
        }

        
        _getLists();
    }, [db])


    // render
    const renderStoryLists = () =>{
        return Object.keys(lists).map(id => {
            const replacedId = id.split(' ').join('-');
            const css_id = `story-${replacedId}`;
            return <Story category = {lists[id].category} className={lists[id].class} id = {css_id} title={id} lists = {lists[id]} key = {id}/>
        })
    }

    return(
        <div>
            <p>test</p>
            <Cover />
            {lists ? renderStoryLists() : <Loading />}
            <Contact title="contact" class="story-contact" />
            <Footer/>
            
        </div>
    )

}

export default Home;