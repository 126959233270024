import React from 'react';

const NotFound = () => {
  return (
    <section>
      <div className="con">
        <div className="wrap-notfound">
          <p>
            notFound
          </p>
        </div>
      </div>
    </section>
  );
}

export default NotFound;