import React from 'react'

function SubmitProtfolio(props){
    return(
        <section className = "SubmitProtfolio">
            <p onClick = {props.event}>SubmitProtfolio</p>
            <InputText />
        </section>
    )
}

const InputText = () =>{
    return(
        <div className="input-text">
            <p>title</p>
            <input type = "text" placeholder = "placeholder" />
        </div>
    )
}

export default SubmitProtfolio;