import React from 'react';

const ContactTable = (props) =>{
    return(
        <div className="contact-table">
            <div className="contact-list">
                <h5>Head Quarter</h5>
                <p>{props.form.headQuarter}</p>
            </div>
            <div className="contact-list">
                <h5>Ecrits-Lab</h5>
                <p>{props.form.ecritsLab}</p>
            </div>
            <div className="contact-list">
                <h5>tell</h5>
                <p>{props.form.tell}</p>
            </div>
            <div className="contact-list">
                <h5>e-mail</h5>
                <p>{props.form.eMail}</p>
            </div>
        </div>
    )
}

export default ContactTable;