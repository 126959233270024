import React from 'react';

const InputText = (props) =>{
  const renderInput = () =>{
      switch(props.type){
          case 'file' :
              return (
                  <div className = "filebox">
                      
                      <input className="upload-name" value={props.filename} disabled="disabled" />
                      <label htmlFor = {props.index}>파일 찾기</label>

                      <input 
                      type = {props.type} 
                      placeholder = {props.name} 
                      name = {props.name}
                      onChange = {props.handleChange}
                      id = {props.index}
                      disabled = {props.disabled}
                      />

                      
                      {/* <div className = "preview-img" style = {{backgroundImage: props.imgRootURL}} /> */}
                  </div>
              )

          default :
              return  (
                  <input 
                    type = {props.type} 
                    placeholder = {props.con_title} 
                    name = {props.name}
                    id = {props.index}
                    onChange = {props.handleChange}
                    disabled = {props.disabled}
                    value = {props.value}
                  />
              )
      }
  }

  return(
      <div className="input-text">
          <p>{props.title}</p>
          {renderInput()}
      </div>
  )
}

export default InputText;