import React from 'react';
import {Link} from 'react-router-dom';
import logo_w from 'img/logo_w.svg';
import logo_color from 'img/logo_color.svg';

class Story extends React.Component{
    state = {
        slider_x: 0
    }

    changeSliderX = () =>{
        const count = Object.keys(this.props.lists.slider).length;
        
        let image_x = this.state.slider_x;

        if( image_x < count -1){
            image_x = image_x + 1;
        }else{
            image_x = 0;
        }

        this.setState({
            slider_x: image_x
        })

    }

    getCategory = () => {
        this.setState({
            category: this.props.category
        }, () => {
            // console.log(this.state);
        })
    }

    componentWillMount(){
        this.getCategory();
    }

    componentDidMount(){
        setInterval(() => {
            if(this.props.lists.slider){
                this.changeSliderX();
            }
        }, 4000);
    }

    render(){
        return(
            <section className = {this.props.className} id = {this.props.id}>
                <div className="sec-full sec-story">
                    <div className="bg-story bg-story-left"></div>
    
                    <div className="con-default">
                        <div className ="story-text">
                            <div className ="story-title">
                                {this.props.className === "blue" 
                                ?  <img src ={logo_w} alt="logo" />
                                : <img src ={logo_color} alt="logo" /> }
                                
                                <h3> / </h3>
                                <h3>{this.props.title}</h3>
                            </div>
                            
                            <p>
                            {this.props.lists.text}
                            </p>
                        </div>
                        
                        <ul className="__cover-btn">
                            <li className="__cover-btn-branding"><Link to={`/${this.state.category}`}>show more</Link></li>
                        </ul>
                    </div>

                    
                    
                    {this.props.lists.slider
                    ? drawSlider(this.props, this.state)
                    : ""}

                </div>
            </section>
        )
    }
}


const drawLists = (props) =>{
    if(props.lists){
        return Object.keys(props.lists.slider).map((id, index) => {
            const bgURL = props.lists.slider[id].url;
            const bgStyle = {
                backgroundImage: "url(" + bgURL +")"
            }
            return <li style = {bgStyle} key = {index}></li>
        })
    }else{
        return;
    }
    
}

const drawSlider = (props, state) => {
    const count = Object.keys(props.lists.slider).length;
    const width = count * 100;
    const left = state.slider_x * -100;

    let slide_wrap_style = {
        width: width + "%",
        left: left + "%"
    }

    return(
        <div className="img-slide">

            <ul className="slide-wrap" style = {slide_wrap_style}>
                {drawLists(props)}
            </ul>

        </div>
    )
}

export default Story;