import React from 'react';
import 'scss/Loading.scss';

function Loading(props){
  return(
    <div className={`Loading ${props.className}`}>
      <div className="wrap-dot">
        <ul>
          <li></li>
        </ul>
      </div>
    </div>
  )
}

export default Loading;