import React from 'react';

class AdminSecTitle extends React.Component{
    render(){
        return(
            <div className = "adminSecTitle">

                <h4>{this.props.title}</h4>
            </div>
        )
    }
}

export default AdminSecTitle;