import React from 'react';
import {Fillter, Posts} from 'components'

import 'scss/portfolio.scss';

class Portfolio extends React.Component{
    state = {
        filter: "all"
    }

    handleFilter = (e) => {
        this.setState({
            filter: e.target.id
        })
    }

    render(){
        return(
            <div className="size-header">
                <Fillter handleFilter = {this.handleFilter} />
                <Posts filter = {this.state.filter} category = "portfolio"/>
            </div>
        )
    }
    
}

export default Portfolio;