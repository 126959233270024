import React from 'react';

function ProjectDetails(props){
    return(
        <div className="projectDetails">
            <div className="con-default">
                <div className="detail-wrap">
                    <p> {props.client}</p>
                    <p> {props.date}</p>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails;