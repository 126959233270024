import React from 'react';

function PageTitle(props){
    return(
        <div className="pagetitle">
            <div className="con-default">
                <h2>{props.title}</h2>
            </div>
        </div>
    )
}

export default PageTitle;
