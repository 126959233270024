import React, {useState, useEffect,useContext} from 'react';
import {HeaderAdmin, AdminTitle, PortfolioLists, AdminSecTitle} from 'components/admin';

import 'scss/admin/admin.scss'
import {AppContext} from '../shared/App';
import Loading from 'components/Loading';

const COLLECTION = 'designtalk';
const FORMINIT = {
	type: "branding",
	title: "",
	client: "",
	date: "",
	description: "",
	thumbnail: "",
	imgName: "",
	imgRoot: "imgRoot",
	imgRootURL: "URL",
	imgFile: null,
	contents: [{
		index: 0,
		type: "text",
		title: "",
		description: "",
		imgName: "",
		imgRoot: "imgRoot",
		imgRootURL: "URL",
		imgFile: null
	}]
}


const AdminEducation = () => {
    // field
    const {db, auth, storage} = useContext(AppContext);
    const [portfolios, setPortfolios] = useState();

    const [addBtnToggle, setAddBtnToggle] = useState(false);
    const [addBtnText, setAddBtnText] = useState('추가');
    
    const [portfolioStyle, setportfolioStyle] = useState({display: "block"});
    const [form_basic, setForm_basic] = useState({
        ...FORMINIT
    });
    const [contents_temp] = useState({
            index: 0,
            type: "text",
            title: "",
            description: "",
            imgName: "",
            imgRoot: "imgRoot",
            imgRootURL: "URL",
            imgFile: null
    });
    const [radioGroup] = useState({image: false, text: true});
    const [isEdit, setIsEdit] = useState(false);
    const [nowId, setNowId] = useState();
    const [prevForm, setPrevForm] = useState();
		const [deleteImage, setDeleteImage] = useState([]);
		const [loading, setLoading] = useState('none');


    useEffect(()=>{
			const _get = async () => {
				return await db
					.ref(COLLECTION)
					.once('value')
					.then(snapshot => {
							return snapshot.val()
					})
					.catch(err => {
							console.log(err);
					})
			}

			const _getPortfolio = async() =>{
        console.log("_getPortfolio");
        const portfolios = await  _get();
				setPortfolios(portfolios)
			}	

			_getPortfolio();

    }, [auth, db])

    const onClickLogout = () => {
        auth.signOut();
    }

    // post
    const _post = async (form) => {
			console.log("- post -");
			const uploadTask = db
				.ref(COLLECTION)
				.push(form);
			let key = "";

			await uploadTask.on('value', function (snapshot) {
				key = snapshot.key;
			});
			return key;
    }

    const _postMainStorage = async (id, form) =>{
			console.log("_postStorage");
			const image = form_basic.imgFile;

			if(!image) {
				return console.log("image thumbnail dont exist");
			}

			// 폼 대표 이미지 넣기
			const ref = storage
				.ref(`${COLLECTION}/${id}/${image.name}`);

			await ref.put(image);
			const link = await ref.getDownloadURL();

			let nextState = {
				...form_basic
			};
			nextState["thumbnail"] = link;
			return nextState;
    }

    // check02-storage
    const _postContentsImage = async (id, form) => {
			console.log("_postContentsImage");
			let newForm = {...form};
			let newContents = form.contents;

			for(let i=0; i<newContents.length; i++){
				let el = newContents[i];
				console.log(el);
				if(!el.imgFile){
					continue;
				}

				const link = await pushStorage(`${COLLECTION}/${id}/detail`, el.imgName, el.imgFile);
				el['contents'] = link;
			}

			newForm.contents = newContents;
			return newForm;
    }

    const handleSubmit = async (form) =>{
			console.log("handleSubmit");
			if (!form.title || !form.client || !form.date || !form.description || !form.imgName) {
				alert("기본정보를 모두 작성해주세요. ");
				return;
			}
			setLoading('active');

			let chkContents = true;
			Object
				.keys(form.contents)
				.map(id => {
						if (form.contents[id].type === "image") {
							if (!form.contents[id].imgName) {
								chkContents = false;
								alert("상세 설정 내용을 모두 작성해주세요. ");
							}
						} else {
							if (form.contents[id].title === "" || form.contents[id].description === "") {
								chkContents = false;
								alert("상세 설정 내용을 모두 작성해주세요. ");
							}
						}
						return "";
				})

			if (chkContents && isEdit) {
				console.log('수정 하기');
				await deletePrevStorage();
				const form_thumbnail = await updateNewStorage();
				const form_contents = await _postContentsImage(nowId, form_thumbnail);
				await updateFbDb(nowId, form_contents);
				window.location.reload();
				console.log('수정 완료');

			} else if (chkContents && !isEdit) {
				console.log('글 쓰기');
				const key_temp = await _post(form_basic);
				const form_temp_1 = await _postMainStorage(key_temp, form_basic);
				const form_temp_2 = await _postContentsImage(key_temp, form_temp_1);
				console.log(form_temp_2);
				await updateFbDb(key_temp, form_temp_2);
				console.log('글 쓰기 완료')
				window.location.reload();

			}
        
        
		}

		const updateNewStorage = async() => {
			const newForm = {...form_basic};
			// upload thumbnail
			if(form_basic.imgFile){
				const link_thumbnail = await pushStorage(`portfolio/${nowId}`, form_basic.imgName, form_basic.imgFile);
				newForm['thumbnail'] = link_thumbnail;
			}

			return newForm;
		}
		
		const pushStorage = async(path, imgName, file) => {
			const ref = storage.ref(`${path}/${imgName}`)
			await ref.put(file);
			const link = await ref.getDownloadURL();
			return link;
		}
		
		const deletePrevStorage = async() => {
			// delete thumbnail
			if (form_basic.imgFile) {
				await _deleteImage(`portfolio/${nowId}`, prevForm.imgName);
			}

			// delete contents image
			if(deleteImage[0]){
				for(let i=0; i<deleteImage.length; i++){
					await _deleteImage(`portfolio/${nowId}/detail`, deleteImage[i]);
				}
			}
		}
		
		const _deleteImage = async(path, name) => {
			try{
				const ref = await storage.ref(path).child(name);
				await ref.delete();
			}catch(err){
				return;
			}
		}

		const updateFbDb = async(id, form) => {
			console.log('-updateFbDb - ');

			await db
				.ref(`${COLLECTION}/${id}`)
				.set(form, err => {
					if (err) {
						console.log(err)
					} else {
						console.log('update success!');
					}
			})
	}


    const handleAddBtn = () =>{
			console.log("HandleAddBtn");
			const newStateToggle = addBtnToggle;
			let display = "";
			let text = "";

			newStateToggle
				? display = "block"
				: display = "none"

			newStateToggle
				? text = "추가"
				: text = "취소"

			setAddBtnToggle(!newStateToggle);
			setAddBtnText(text);
			setportfolioStyle({display: display});

			// 취소
			if (addBtnToggle) {
				console.log(FORMINIT);
				setForm_basic(FORMINIT);
				setIsEdit(false);
				setNowId(null);
				setPrevForm(null);
			}
    }

    const handleChange  = type => (e) =>{
			const id = e.target.name;

			// date의 경우 -를 .으로 바꿔주기
			switch (id) {
					// date 날짜 변경
					case 'date':
						{
							const newForm = form_basic;
							newForm[id] = e
									.target
									.value
									.replace(/-/gi, ".");
							setForm_basic({
									...newForm
							});
							break;
						}
						// 썸내일 이미지 불러오기
					case 'imgName':
							{
								// check file image
								if (!e.target.files[0].type.match(/image\//)) 
										return;
								var reader = new FileReader();
								const newForm = form_basic;
								reader.onload = function (e) {
										const imgRoot = e.target.result;
										newForm["imgRoot"] = imgRoot;
										newForm["imgRootURL"] = "url(" + imgRoot + ")";
								}

								reader.readAsDataURL(e.target.files[0]);
								newForm[id] = e.target.files[0].name;
								newForm["imgFile"] = e.target.files[0];
								setForm_basic({
										...newForm
								});
								break;
							}

					case 'contents':
							{
								const newForm = {...form_basic};
								const index = e.target.id;
								const indexArraw = index.split('_');
								const nowIndex = indexArraw[indexArraw.length - 1]
								const con_title = e.target.placeholder;

								if (e.target.type === "file") {

									if(nowId&&newForm[id][nowIndex].contents){
										// 삭제 파일 추가
										console.log('test')
										const newImage = addDeleteImage(newForm[id][nowIndex].imgName)
										console.log(newImage);
										setDeleteImage(newImage);
									}

									if (!e.target.files[0].type.match(/image\//)) 
											return;
									var readerContents = new FileReader();

									readerContents.readAsDataURL(e.target.files[0]);
									newForm[id][nowIndex]["imgName"] = e.target.files[0].name;
									newForm[id][nowIndex]["imgFile"] = e.target.files[0];

								} else {
										Object
												.keys(newForm[id][nowIndex])
												.map(key => {
														if (key === con_title) {
																newForm[id][nowIndex][key] = e.target.value
														}
														return "";
												})
								}
								setForm_basic({
									...newForm
								});
								break;
							}

					default:
							{
									const newForm = form_basic;
									newForm[id] = e.target.value;
									setForm_basic({
											...newForm
									});

									break;
							}

			}
    }
		const addDeleteImage = (name) => {
			const new_arr = deleteImage
			new_arr.forEach(el => {
				if(el === name) return new_arr;
			})
			new_arr.push(name);
			return new_arr;
		}

    const handleToggleChange = index => (e) => {
			const index_temp = index.split("_")[1];
			const newContents = form_basic.contents.map(el => {
				if(`${el.index}`===index_temp){
					// when edit & add delete iamge
					if(nowId&&el.contents){
						const new_arr = addDeleteImage(el.imgName);
						setDeleteImage(new_arr);
					}

					el = {...contents_temp}
					el.index = index_temp;
					el.type = e.target.value
				}
				return el;
			})

			setForm_basic({
				...form_basic,
				contents: newContents
			});

    }

    const addInputContents = () =>{
      console.log("addInputContents");
			
			let newForm = copyObj(form_basic);
			let newContentsTemplate = copyObj(contents_temp);

			let newContents = form_basic.contents;
			
			const newCount = newContents.length;
			newContentsTemplate["index"] = newCount;

			newContents.push(newContentsTemplate);
			newForm["contents"] = newContents;
			
			setForm_basic({
				...form_basic,
				contents: newContents
			})
    }

    const _delete = async (id) =>{
			await db
			.ref(`${COLLECTION}/${id}`)
			.remove();
    }

    const _deleteStorage = async (id, portfolio) =>{
			await storage.ref(`${COLLECTION}/${id}/`).child(portfolio.imgName).delete();

			Promise.all(portfolio.contents.map(el => {
					if (el.type === "image") {
						storage.ref(`${COLLECTION}/${id}/detail/`).child(el.imgName).delete();
					}
					return null;
			}))
    }

    const handlePortfolioDelete = async (id) =>{
			if (window.confirm("정말 삭제하시겠습니까?")) {
				await _deleteStorage(id, portfolios[id]);
				await _delete(id);
				window.location.reload();
			} else {
					return;
			};
		}
		
		const handlePortfolioEdit = (id) => {
			setIsEdit(true);
			setNowId(id);
			let display = "";
			let text = "";

			addBtnToggle
				? display = "block"
				: display = "none"

			addBtnToggle
				? text = "추가"
				: text = "취소"

			setAddBtnToggle(!addBtnToggle);
			setAddBtnText(text);
			setportfolioStyle({display: display});

			Object
				.keys(portfolios)
				.forEach(el => {
					if (el === id) {
						const form = portfolios[id];
						setForm_basic(form);
						setPrevForm(copyObj(form));
					}
				})

		}
		
		const copyObj = (obj) => {
			var copy = {};
			if (Array.isArray(obj)) {
				copy = obj.slice().map((v) => {
					return copyObj(v);
				});
			} else if (typeof obj === 'object' && obj !== null) {
				for (var attr in obj) {
					if (obj.hasOwnProperty(attr)) {
						copy[attr] = copyObj(obj[attr]);
					}
				}
			} else {
				copy = obj;
			}
			return copy;
		}



    const contentsDelete = () => {
			console.log("contentsDelete");
			const newForm = {
				...form_basic
			};
			const newContents = {
				...form_basic.contents
			};
			let newCount = Object.keys(newContents).length;
			
			// check to delete image
			if(newContents[newCount-1].contents){
				const newImage = addDeleteImage(newContents[newCount-1].imgName);
				setDeleteImage(newImage);
			}

			delete newContents[newCount-1];
			newForm["contents"] = newContents;
			newCount = newCount - 1;

			setForm_basic(newForm);

    }

        return(
            <div className="admin  size-header">
                <HeaderAdmin/>
                <Loading className={loading}/>
                <div className = "con-admin">
                    <AdminTitle title = "designtalk" addBtn = "false"  onClickLogout = {onClickLogout}/>
                    
                    <div className = "wrap-btn">
											<p className = "btn portfolioBtn" onClick = { handleAddBtn}>{ addBtnText}</p>
                    </div>

                    {/* 리스트 보여주기 */}
                    {portfolios 
                    ? <PortfolioLists 
                    style = {portfolioStyle} 
                    portfolios = {portfolios} 
                    addBtnToggle = {addBtnToggle}
										handlePortfolioDelete = {(id) => { handlePortfolioDelete(id)}} 
										handlePortfolioEdit={(id) => {handlePortfolioEdit(id)}}/>
                    : "No designtalks" }

                    {/* 입력폼 보여주기 */}
                    { addBtnToggle
                    ? <SubmitProtfolio 
                    form_basic = { form_basic}
                    radioGroup = { radioGroup}
                    handleChange = { handleChange}
                    handleToggleChange = { handleToggleChange}
                    addInputContents = { addInputContents}
                    contentsDelete = { contentsDelete}
                    />
                    :""}

                    {/* 확인 버튼 */}
                    { addBtnToggle ? 
                    <div className = "wrap-btn wrap-btn-confirm">
                        <p className = "btn btn-confirm" onClick = {() =>  handleSubmit( form_basic)}> 확인 </p>
                    </div>
                    : <div></div>}

                </div>
            </div>
        )
}

function SubmitProtfolio(props){

	const date_temp = props.form_basic.date;
	const date_result = date_temp.replace(/[.]/g, '-');
    return(
        <section className = "SubmitProtfolio">

            <AdminSecTitle title = "기본 정보"/>

            <InputText con_title = "제목" handleChange = {props.handleChange} title = "제목" name = "title" type = "text" value={props.form_basic.title}/>
            <InputText con_title = "클라이언트"  handleChange = {props.handleChange} title = "클라이언트" name = "client"  type = "text" value={props.form_basic.client}/>
            <InputText con_title = "날짜" handleChange = {props.handleChange} title = "날짜" name = "date"  type = "date" value={date_result}/>
            <InputText con_title = "설명" handleChange = {props.handleChange} title = "설명" name = "description"  type = "text" value={props.form_basic.description}/>

            <InputText 
            handleChange = {props.handleChange} 
            title = "기본 이미지" 
            name = "imgName"  
            type = "file"
            index = "imgName"
            filename = {props.form_basic.imgName}
            imgRootURL = {props.form_basic.imgRootURL}
            />

            <AdminSecTitle title = "상세 설정"/>
            <div className="AdminSecTitle">
            {Object.keys(props.form_basic.contents).map( el => {
                const contents = props.form_basic.contents;
                const id = "contentsInputId_" + contents[el].index;
                const radioTextId = "radioTextId_" + contents[el].index;
                const radioImageId = "radioImageId_" + contents[el].index;
                
                return <InputContents
                        handleChange = {props.handleChange}
                        contentsDelete = {props.contentsDelete}

                        inputImageId = {id}
                        inputTextId = {id}
                        inputRadioTextId = {radioTextId}
                        inputRadioImageId = {radioImageId}
                        
                        inputIndex = {id}

                        content = {contents[el]}
                        type = {contents[el].type}
                        radioGroup = {props.radioGroup}
                        handleToggleChange = {props.handleToggleChange}
                        key = {id}
                        index = {contents[el].index}
                        />
                })}
                </div>
            

            <div className = "wrap-btn">
                <p className = "btn btn-addContents" onClick = {props.addInputContents} >추가하기</p>
            </div>
        </section>
    )
}

const InputContents = (props) => {
    return(
        <div className = "InputContents" >
            <div className="input-header">

                <div className = "input-contents-radio">
                    <input 
                    type = "radio" 
                    id = {props.inputRadioTextId} 
                    name = {props.inputRadioTextId}
                    checked = {props.type === "text"}
                    onChange = {props.handleToggleChange(props.inputRadioTextId)}
                    value = "text"
                    />
                    <label htmlFor={props.inputRadioTextId}>텍스트</label>

                    <input 
                    type = "radio" 
                    id = {props.inputRadioImageId} 
                    name = {props.inputRadioImageId}
                    checked = {props.type === "image"}
                    onChange = {props.handleToggleChange(props.inputRadioImageId)}
                    value = "image"
                    />
                    <label htmlFor={props.inputRadioImageId}>이미지</label>
                </div>

                <div className="btn btn-contents-delete">
                    <p onClick = {props.contentsDelete}>삭제</p>
                </div>
            </div>

            <div className="input-body">
                {props.type === "text" 
                ? <div className = "wrap-text">
                    <InputText 
                    handleChange = {props.handleChange} 
                    title = "소제목" 
                    name = "contents"
                    con_title = "title"
                    type = "text"
                    index = {props.inputIndex}/>

                    <InputText 
                    handleChange = {props.handleChange} 
                    title = "설명" 
                    name = "contents" 
                    con_title = "description"
                    type = "text"
                    index = {props.inputIndex}/>
                    
                </div>
                : <div className = "wrap-img">
                    {/* contents 의 인덱스로 들어가야 한다. */}
                    <InputText 
                    index = {props.inputIndex}
                    handleChange = {props.handleChange}
                    title = "이미지" 
                    name = "contents"
                    type = "file" 
                    filename = {props.content.imgName}
                    // imgRootURL = {props.form_basic.imgRootURL}
                    />           
                </div> }
            </div>
        </div>
    )
}

const InputText = (props) =>{
    const renderInput = () =>{
        switch(props.type){
            case 'file' :
                return (
									<div className = "filebox">
											
										<input className="upload-name" value={props.filename? props.filename: "파일"} disabled="disabled" />
										<label htmlFor = {props.index}>{props.value ? props.value: "파일 찾기"}</label>

										<input 
										type = {props.type} 
										placeholder = {props.name} 
										name = {props.name}
										onChange = {props.handleChange(props.type)}
										id = {props.index}
										/>
									</div>
                )

            default :
                return  (
                    <input 
                    type = {props.type} 
                    placeholder = {props.con_title} 
                    name = {props.name}
                    id={`${props.con_title}_${props.index}`}
										onChange = {props.handleChange(props.type)}
										value = {props.value}
                    />
                )
        }
    }

    return(
        <div className="input-text">
            <p>{props.title}</p>
            {renderInput()}
        </div>
    )
}

export default AdminEducation;