import React, { useEffect,useContext} from 'react';
import {HeaderAdmin, AdminTitle, AdminSecTitle, SlideLists} from 'components/admin';

import 'scss/admin/admin.scss'
import { AppContext } from '../shared/App';

const AdminHome =() =>{
    const {auth} = useContext(AppContext);

    const onClickLogout = () => {
        auth.signOut();
    }

    useEffect(() =>{
        auth.onAuthStateChanged(me => {
            if(me){
                // console.log(me);
            }else{
                // console.log("not logged in");
                // window.location = "../admin"
            }
        });
    })


    return(
        <div className="admin size-header">
            <HeaderAdmin />

            <div className = "con-admin">
                <AdminTitle title = "home" addBtn = "false" onClickLogout = {onClickLogout}/>
                <AdminSecTitle title = "meta branding" db = "brand"/>
                <SlideLists title = "meta branding"/>

                <AdminSecTitle title = "social solution design"  db = "study"/>
                <SlideLists title = "social solution design"/>

                <AdminSecTitle title = "design talk"  db = "study"/>
                <SlideLists title = "design talk"/>
            </div>
        </div>
    )

    
}

export default AdminHome;