import React from 'react';

function AdminTitle(props){
    return(
        <div className = "adminTitle">
            <h3>{props.title}</h3>
            {props.addBtn === "true" ? <p className="btn" >추가</p> : null}
            {!props.type ? 
            <p onClick = {props.onClickLogout}>로그아웃</p> :
            ""}
            
            
        </div>
    )
}

export default AdminTitle;