const firebaseConfig = {
    apiKey: "AIzaSyCDHpHCqlRbMtMkCGlCTGpdk1XVPpKXyZ0",
    authDomain: "ecrits-web-8f4bf.firebaseapp.com",
    databaseURL: "https://ecrits-web-8f4bf.firebaseio.com",
    projectId: "ecrits-web-8f4bf",
    storageBucket: "ecrits-web-8f4bf.appspot.com",
    messagingSenderId: "44688427677",
    appId: "1:44688427677:web:1295b18e6b3ce0e174a274"

  };
  
  export default firebaseConfig;