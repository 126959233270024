import React from 'react';

class Cover extends React.Component{
    state={
        patternStyle: ['', 'pattern-study', '', 'pattern-branding','', 'pattern-design'],
        nowPatternStyle: 'pattern-study',
        styleCount: 0
    }

    changePattern = () => {
        console.log('changePattern')
        const pattern = document.querySelectorAll('.pattern');

        setInterval(() => {
            let styleCount = this.state.styleCount;
            let nowPatternStyle = this.state.nowPatternStyle;

            pattern.forEach(el => {
                el.className = `pattern ${this.state.nowPatternStyle}`;
            })

            styleCount = styleCount+1
            if(styleCount === this.state.patternStyle.length){
                styleCount = 0;
            }
            nowPatternStyle = this.state.patternStyle[styleCount];
            this.setState({
                styleCount: styleCount,
                nowPatternStyle: nowPatternStyle
            });
            
        }, 4000);
    }

    componentDidMount(){
        if(window.innerHeight>750 && window.innerWidth <= 1024){
            this.changePattern();
        }
    }

    render(){
        return(
            <section>
                <div className="sec-cover sec-full">
                    <div className="con-default">
                        <div className ="__cover-text">
    
                            <h1>We think about the value of life {window.innerWidth > 587 ? <br/> : ""}beyond the value of design </h1>

                                <ul className="__cover-btn">
                                    <li className="__cover-btn-talk" key="1"><a href="#story-design-talk" >Design Talk</a></li>
                                    <li className ="__cover-btn-branding" key="2"><a href="#story-meta-branding" >Meta Branding</a></li>
                                    <li className ="__cover-btn-design" key="3"><a href="#story-social-solution-design" >Social Solution Design</a></li>
        
                                    {window.innerWidth < 1200 &&  window.innerWidth >= 768 && window.innerHeight > 750 ? 
                                    <CoverPattern row="3" col="4"/> :
                                    window.innerWidth < 768  && window.innerHeight > 750? 
                                    <CoverPattern row="2" col="4"/> :
                                    window.innerHeight < 750 ?
                                    "" :
                                    <CoverPattern row="4" col="5"/>}
                                    
                                </ul>
                            
                        </div>
                    </div>
    
                </div>
    
            </section>
        )
    }
}



function CoverPattern(e){
    const patterns = [];
    let key = 0;

    for(let i=0; i<e.row; i++){
        for(let j=0; j<e.col; j++){
            const pattern = (
                <div className="pattern" key={key}>
                    <div className="pattern-dot"></div>
                </div>
            )
            patterns.push(pattern);
            key++;
        }
    }

    return (
        <div className="patternWrap">
            {patterns}
            <div className="pattern-center"></div>
        </div>
    )
}

export default Cover;