import React, {createContext} from 'react';
import {Switch,Route,Redirect } from 'react-router-dom';

import { Home, Portfolio, Education, Contact, Detail, Detail_designTalk, 
          AdminSignin, AdminHome, AdminPortfolio, AdminEducation, AdminContact,
          NotFound,
        } from 'pages';

import Header from 'components/Header';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import firebaseConfig from '../lib/firebase-config';
firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export const AppContext = createContext()

class App extends React.Component {
  state = {
    admin: false,
    redirectToLogin: false
  }

  componentDidMount(){
    auth.onAuthStateChanged(me => {
      if(me){
        // console.log(me);
      }else{
        this.setState({
          redirectToLogin: true
        })
      }
    });
  }

  render(){
    return (
      <div className="App">
      
        <Header />
        <AppContext.Provider value = {{db, storage, auth}}>
          <Switch>
            <Route exact path="/" component={Home} />

            <Route exact path="/portfolio" component={Portfolio} />
            <Route path="/portfolio/:id" render = {({match}) => (<Detail  match = {match}/>) }/>
            <Route exact path="/designtalk" component={Education} />
            <Route exact path="/designtalk/:id" component = {Detail_designTalk} />
            <Route exact path="/contact" component={Contact} />

            <Route exact path="/admin" component={AdminSignin} />
            <Route path="/adminhome" render={() => (this.state.redirectToLogin ?  <Redirect to="/admin"/> : <AdminHome />)} />
            <Route path="/adminportfolio" render={() => (this.state.redirectToLogin ?  <Redirect to="/admin"/> : <AdminPortfolio />)} />
            <Route path="/admindesigntalk" render={() => (this.state.redirectToLogin ?  <Redirect to="/admin"/> : <AdminEducation />)} />
            <Route path="/admincontact" render={() => (this.state.redirectToLogin ?  <Redirect to="/admin"/> : <AdminContact />)} />

            <Route component={NotFound} />
          </Switch>
        </AppContext.Provider>
       
      </div>
    );
  }
}

export default App;
