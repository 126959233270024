
import React, {useState, useEffect,useContext} from 'react';

//fire base fileuploader 
import FileUploader from 'react-firebase-file-uploader';
import { AppContext } from '../../shared/App';


const SlideLists =(props)=>{
    const {db, storage} = useContext(AppContext);
    const [thumbnail, setThumbnail] = useState();

    useEffect(()=>{
        const _getApi = async () =>{
            return await db.ref(`homeThumbnail/${props.title}/slider`).once('value')
            .then(function(snapshot) {
                return snapshot.val();
            })
            .catch(err => {
                console.log(err);
            })
        }
    
        const _getlists = async () =>{
            const thumbnail = await  _getApi();
            setThumbnail(thumbnail);
        }

        _getlists();
    }, [db,props])

    // get
    

     // post
     const _post = (obj) => {
        console.log("post");
        const uploadTask =  db.ref(`homeThumbnail/${props.title}/slider`).push(obj);
        let key = "";

        uploadTask.on('value', function(snapshot){
            key = snapshot.key;
        })

        let nextThumbnail = null;
        // // thumbnail 이 있을 때
        if(thumbnail){
            nextThumbnail = thumbnail;
        }else{
            nextThumbnail = {}
        }

        nextThumbnail[key] = obj;
        setThumbnail(nextThumbnail);
        window.location.reload();
    }


    

    const deletePhoto = (name, cate) =>{
        console.log("deletePhoto");
        storage.ref(`slider/${cate}/`).child(name).delete();
    }

    const handleUploadStart = () =>{
        console.log('start upload');
    }

    const handleUploadSuccess = filename =>{
        console.log('image upload success!')
        // STORAGE
        storage.ref(`slider/${props.title}`).child(filename).getDownloadURL()
        .then(url => {
            // DB
            const thumbnail = {
                url: url,
                name: filename
            }
            if(!thumbnail.url){
                return;
            }
            _post(thumbnail);
        })
        .catch(err =>{
            console.log(err);
        })
    }

    // delete
    const _delete=async (id, cate)=>{
        await db.ref(`homeThumbnail/${cate}/slider/${id}`).remove();
        window.location.reload();
        let nextState = thumbnail;
        delete nextState[id];
        setThumbnail(nextState);

    }

    const handleDelete = (id, cate, name) =>{
        _delete(id, cate);
        deletePhoto(name, cate);
    }

    const renderLists=() =>{
        const res = Object.keys(thumbnail).map((id, index) =>{
            const url = thumbnail[id].url;
            const name = thumbnail[id].name;
            return <List 
            url = {url}
            key = {index}
            id = {id}
            category = {props.title}
            name = {name}
            event = {(id, cate) => {handleDelete(id, cate, name)}}/>
        })
        return res;

    }

    return(
        <div>
            <div className="wrap-input">

                <FileUploader 
                    accept = "image/*"
                    name="image"
                    storageRef = {storage.ref(`slider/${props.title}`)}
                    onUploadStart = {handleUploadStart}
                    onUploadSuccess = {handleUploadSuccess}
                />

            </div>
            
            <div className = "SlideLists">
                {thumbnail ? renderLists() : "NOTHING"}
            </div>
        </div>
    )
}

function List(props){
    const style = {
        backgroundImage: "url(" + props.url + ")"
    }

    return(
        <div className = "SlideList" style = {style}>
            <p className="btn btn-del" onClick = {() => {props.event(props.id, props.category)}}>삭제</p>
        </div>
    )
}

export default SlideLists;