import React from 'react';

function Fillter(props){
    const changeFilter = (e) => {
        // console.log(e);
        const fillter = document.querySelector('#fillter');
        const list = fillter.querySelectorAll('li');

        if(e.target === fillter){
            
            return;
        }
        
        list.forEach(function(e){
            e.classList.remove("fillter-active");
        })
        console.log(e.target);
        e.target.classList.add("fillter-active");
    

        
    }

    return(
        <div className="fillter" >
            <div className="con-default" >
                <ul id="fillter" onClick = {changeFilter}>
                    <li className="fillter-active" id = "all" onClick = {props.handleFilter}><p>all</p></li>

                    <li id = "branding" onClick = {props.handleFilter}><p>meta branding</p></li>
                    <li id = "design" onClick = {props.handleFilter}><p>social solution design</p></li>

                </ul>
            </div>
        </div>
    )
}


export default Fillter;