import React from 'react';
import logo_w from 'img/logo_w.svg'

function Footer(){
    return(
        <footer>
            <img src = {logo_w} alt="logo"/>
            <p>Copyright 2019. Ecrits rights reserved.</p>
        </footer>
    )
}

export default Footer;