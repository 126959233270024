import React, {useState, useEffect, useContext} from 'react';
import {HeaderAdmin, AdminTitle} from 'components/admin';
import { AppContext } from '../shared/App';
import '../scss/admin/admin.scss';

const AdminSignin = () => {
  const {auth} = useContext(AppContext);
  const [form, setForm] = useState({});

  useEffect(() => {
    auth.onAuthStateChanged(me => {
      if(me){
        console.log(me);
        window.location = "adminhome";
      }else{
        console.log("not logged in");
      }
    });

  }, [auth])

  const handleChange = (e) => {
    const newState =  form;
    const name = e.target.name;
    newState[name] = e.target.value;
    setForm(newState);
  }

  const _login = () => {
    const promise =  auth.signInWithEmailAndPassword( form.id,  form.password);
    promise.catch(e => alert(e.message));
  }

  const handleLoginBtn = () => {
     _login();
  }


    return(
      <div className = "Admin-main  size-header">
        <HeaderAdmin type = "false"/>
  
        <div className = "con-admin">
          <AdminTitle title = "log-in" addBtn = "false" type = "false"/>
  
          <div className = "">
            <InputText title = "id" type="text" con_title = "id" handleChange = { handleChange}/>
            <InputText title = "password" type="password" con_title = "password" handleChange = { handleChange}/>
          </div>

          <div className = "wrap-btn">
              <p className = "btn btn-login" onClick = { handleLoginBtn} >로그인</p>
          </div>
          
        </div>
      </div>
    )

}

const InputText = (props) =>{
  const renderInput = () =>{
    return  (
        <input 
        type = {props.type} 
        placeholder = {props.con_title} 
        name = {props.title}
        id = {props.index}
        onChange = {props.handleChange}
        />
    )
  }

  return(
      <div className="input-text">
          <p>{props.title}</p>
          {renderInput()}
      </div>
  )
}

export default AdminSignin;