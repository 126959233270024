import React from 'react';

function ProjectImage(props){
    return(
        <div className = "projectImage contents">
            <div className = "con-default">
                <img src = {props.img} alt={props.name}/>
            </div>
        </div>
    )
}

export default ProjectImage;