import React from 'react';

function ContactMap(props){
    const style = {
        backgroundImage: `url(${props.map.imgURL})`
    }
    
    return(
        <div className="contact-map">
            <div className="con-default" style = {style}>

            </div>
        </div>
    )
}


export default ContactMap;