import React from 'react';


function ProjectDescript(props){
    return(
        <div className="ProjectDescript">
            <div className="con-default">
                <p className="width-description">{props.description}</p>
            </div>
        </div>
    )
}

export default ProjectDescript;